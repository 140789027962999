import MyPage from '../module/MyPage.js';
import MyMasonry from '../module/MyMasonry.js';
import AOS from 'aos';

export default class Actualities extends MyPage {

	constructor() {

		super();

		this.name = 'actualities';

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// aos
		AOS.refreshHard();

		// masonry
		this.masonry = new MyMasonry('.news');
		this.masonry.load();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// masonry
		if (this.masonry !== undefined)
		{
			this.masonry.unload();
			delete this.masonry;
		}

	}
}