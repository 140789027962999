import PubSub      from 'pubsub-js';
import anime       from 'animejs/lib/anime.es.js';
import Console     from './common/Console.js';
import Util        from './common/Util.js';
import Loading     from './common/Loading.js';
import Preloading  from './common/Preloading.js';
import Navigation  from './Navigation.js';
import Scroll      from './common/Scroll.js';
import Top         from './common/Top.js';
import Navigate    from './common/Navigate.js';

import Actualities  from './page/Actualities.js';
import Actuality    from './page/Actuality.js';
import Home         from './page/Home.js';
import Newsletter   from './page/Newsletter.js';
import Page         from './page/Page.js';
import Project      from './page/Project.js';
import Projects     from './page/Projects.js';

export default class Transition {

	constructor() {

		this.firstRun = true;

		this.isPromise = false;

		this.classesIncludes =
		{
			'Actualities': Actualities,
			'Actuality': Actuality,
			'Home': Home,
			'Newsletter': Newsletter,
			'Page': Page,
			'Project': Project,
			'Projects': Projects
		};

	}

	/*
	** load
	*/

	load (page, request, isPopState) {

		Console.log('Transition::load('+ page +','+ request.path +','+ isPopState +')');

		// promise
		if (this.isPromise)
		{
			return false;
		}

		// first run
		if (this.firstRun)
		{
			// request
			this.currentRequest = request;

			this.currentRequest.scrollTop = 0;

			// build
			this.build(page);
		}
		 else if ( Util.cleanPath(this.currentRequest.path) !== Util.cleanPath(request.path) )
		{
			// Navigate
			Navigate.backEnabled();

			// request
			this.previousRequest = this.currentRequest;

			this.currentRequest = request;

			this.currentRequest.scrollTop = Util.getScrollTop();

			// loading
			Loading.show();

			// leave
			this.isPromise = true;

			this.leave().then(() =>
			{
				// get
				this.get(request.path).then(response =>
				{
					// unload
					this.unload();

					// container
					this.container.innerHTML = response.getElementById('container').innerHTML;

					// build
					this.build(page);

					// scrollToTop
					if (!isPopState)
					{
						Util.scrollToTop();
					}
					 else
					{
						// warning : no css transition to body.
						//Util.setScrollTop(this.previousRequest.scrollTop);
						this.pageInstance.scrollTo(this.previousRequest.scrollTop);
					}

					// promise
					this.isPromise = false;

				}).catch(message =>
				{
					Console.log('Transition::load error ('+ message +')');

					this.isPromise = false;
				});

			});

		}

		// transition
		PubSub.publish('transition.load');

	}

	get (url) {

		Console.log('Transition::get('+ url +')');

		return new Promise( (resolve, reject) =>
		{
			const xhr = new window.XMLHttpRequest();

			xhr.onreadystatechange = () =>
			{
				if (xhr.readyState === 4)
				{
					if (xhr.status === 200)
					{
						resolve(xhr.responseXML);
					}
					 else
					{
						reject(xhr);
					}
				}
			};

			xhr.open('GET', url, true);
			xhr.responseType = 'document';
			xhr.send();
		});

	}

	/*
	** unload
	*/

	unload() {

		Console.log('Transition::unload');

		if (this.pageInstance !== undefined)
		{
			this.pageInstance.unload();

			delete this.pageInstance;
		}

	}

	/*
	** build
	*/

	buildFirst() {

		Console.log('Transition::buildFirst');

		const page = document.getElementById('section').getAttribute('data-page');

		this.build(page);

	}

	build (page) {

		Console.log('Transition::build('+ page +')');

		// current
		this.currentPage = page;

		// section
		const section = document.getElementById('section');

		// title
		document.title = section.getAttribute('data-title');

		// navigation
		const controller = section.getAttribute('data-controller');
		const id_category = section.getAttribute('data-category-id');

		Navigation.setActive(controller, id_category);

		// alt
		Util.removeAlt('.section');

		// blank
		Util.addBlank('.wysiwyg');

		// page
		const className = Util.capitalize(page);
		this.pageInstance = new this.classesIncludes[className]();
		this.pageInstance.load();

	}

	/*
	** enter
	*/

	enter() {

		Console.log('Transition::enter');

		// tween
		anime({
			targets  : this.container,
			opacity  : 1,
			easing   : 'easeOutQuint',
			duration : 0,
			complete : (anim) =>
			{
				this.pageInstance.enter()
			}
		});

		// class
		document.body.classList.remove('body--transition');

		// loading
		Loading.hide();

		// scroll
		this.scroll.update();

		// top
		this.top.update();

	}

	/*
	** leave
	*/

	leave() {

		Console.log('Transition::leave');

		// page leave
		if (this.pageInstance !== undefined)
		{
			this.pageInstance.leave();
		}

		// class
		document.body.classList.add('body--transition');

		// return
		return new Promise((resolve, reject) =>
		{
			anime({
				targets  : this.container,
				opacity  : 0,
				easing   : 'easeOutQuint',
				duration : 0,
				complete : () => resolve()
			});
		});

	}

	/*
	** start
	*/

	start() {

		Console.log('Transition::start');

		// container
		this.container = document.getElementById('container');

		// top
		this.top = new Top();
		this.top.load();

		// scroll
		this.scroll = new Scroll();
		this.scroll.load();

		// subscribe
		PubSub.subscribe('page.ready', (msg, data) =>
		{
			if (this.firstRun)
			{
				Preloading.hide(() =>
				{
					this.firstRun = false;

					this.pageInstance.enter();
				});
			}
			 else
			{
				this.enter();
			}
		});

		// preloading
		Preloading.show();

	}

}