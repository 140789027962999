import MyPage from '../module/MyPage.js';
import Poster from '../module/Poster.js';
import Quote from '../module/Quote.js';

export default class Page extends MyPage {

	constructor() {

		super();

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// poster
		this.poster = new Poster();
		this.poster.load();

		// quote
		this.quote = new Quote();
		this.quote.load();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// poster
		if (this.poster !== undefined)
		{
			this.poster.unload();
			delete this.poster;
		}

		// quote
		if (this.quote !== undefined)
		{
			this.quote.unload();
			delete this.quote;
		}

	}
}