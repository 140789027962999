import Console from '../common/Console.js';


export default class Works {

	constructor () {

		this.DOM = {};

		this.count = 0;

		this.DOM.body = document.body;

		this.timeoutDelay = 800;

	}

	/*
	** init
	*/

	init() {

		this.DOM.rollover = this.DOM.body.querySelector('.rollover');

		if ( this.DOM.rollover === null )
		{
			return false;
		}

		this.DOM.rolloverLeft = this.DOM.rollover.querySelector('.rollover__left');

		this.DOM.rolloverRight = this.DOM.rollover.querySelector('.rollover__right');

		this.DOM.items = this.DOM.body.querySelectorAll('.works__item');

		this.count = this.DOM.items.length;

		for (let i=0; i < this.count; i++)
		{
			const el = this.DOM.items[i];
			const name = el.querySelector('.works__name');
			const subtitle = el.querySelector('.works__subtitle');

			el.addEventListener('mouseenter', event =>
			{
				// html
				this.DOM.rolloverLeft.innerHTML = name.innerHTML;
				this.DOM.rolloverRight.innerHTML = subtitle.innerHTML;

				// hover
				this.DOM.rollover.classList.add('hover');
				el.classList.add('hover');

				// idle
				this.clearTimeout();

				// class
				this.DOM.body.classList.add('body--hover');

				this.timeout = window.setTimeout( () =>
				{
					this.DOM.body.classList.add('body--idle');

				}, this.timeoutDelay);

			}, false);

			el.addEventListener('mouseleave', event =>
			{
				// html
				this.DOM.rolloverLeft.innerHTML = '';
				this.DOM.rolloverRight.innerHTML = '';

				// hover
				this.DOM.rollover.classList.remove('hover');
				el.classList.remove('hover');

				// idle
				this.clearTimeout();

				// class
				this.DOM.body.classList.remove('body--hover');
				this.DOM.body.classList.remove('body--idle');

			}, false);
		}

	}

	clearTimeout() {

		if (this.timeout !== undefined)
		{
			clearTimeout(this.timeout);
			delete this.timeout;
		}

	}

	/*
	** load
	*/

	load() {

		Console.log('Works::load');

		// init
		this.init();

	}

	/*
	** unload
	*/

	unload() {

		Console.log('Works:unload');

		// timeout
		this.clearTimeout();

		// class
		this.DOM.body.classList.remove('body--idle');
		this.DOM.body.classList.remove('body--hover');

	}

}