import PubSub from 'pubsub-js';
import Observer from './Observer.js';
import Text from '../class/Text.js';


export default class Quote {

	constructor() {

		this.DOM = {};

		this.texts = [];

	}

	/*
	** load
	*/

	load() {

		this.DOM.items = document.querySelectorAll('.wysiwyg--quote p');

		this.count = this.DOM.items.length;

		for (let i = 0; i < this.count; ++i)
		{
			const item = this.DOM.items[i];

			item.setAttribute('data-index', i);

			this.texts[i] = new Text( item );
		}

		/* observer */
		this.observer = new Observer('.wysiwyg--quote p', 0.2, '_txt');

		this.pubSubObserverIntersect = PubSub.subscribe('observer_txt.intersect', (msg, data) =>
		{
			const index = data.el.getAttribute('data-index');

			this.texts[index].enter();
		});

		this.observer.load();

	}

	/*
	** unload
	*/

	unload() {

		// observer
		if (this.observer !== undefined)
		{
			PubSub.unsubscribe(this.pubSubObserverIntersect);
			delete this.pubSubObserverIntersect;

			this.observer.unload();
			this.observer = undefined;
		}

		// text
		this.texts = [];

	}

}