import PubSub from 'pubsub-js';
import Instafeed from 'instafeed.js';

export default class Instagram {

	constructor() {

		this.DOM = {};

		this.DOM.body = document.body;

		this.template = '<div class="feed__item"><div class="feed__wrapper"><a class="feed__anchor" href="{{link}}" target="_blank"><img src="{{image}}" /></a></div></div>';

		// 1 oct 2024
		this.token = 'IGQWRNOVJtVW9MamltTXlnOTU2RG9naFFuZA3RmaFFQMUUwR2xoWXA3VTctM3VsWkdfeHRsbUF6czA4bmZAtYWlBcjZARTndxU3VhdWc0RWhLWXpmS2FmMjFnYUNzUkVGZAVFRRnR6SEhHVVRuYTNyYzk0dzEtV3NYTFkZD';

	}

	/*
	** init
	*/

	init() {

		this.DOM.container = document.getElementById('instagram');

		if (this.DOM.container === null)
		{
			return false;
		}

		// button
		this.DOM.button = document.querySelector('#instagram-button .socials__overlay');

		if (this.DOM.button === null)
		{
			return false;
		}

		this.DOM.button.addEventListener('click', event =>
		{
			event.preventDefault();
			event.stopPropagation();

			this.open();

			return false;

		}, false);

		// close
		this.DOM.close = this.DOM.container.querySelector('.instagram__close');
		this.DOM.close.addEventListener('click', event =>
		{
			event.preventDefault();
			event.stopPropagation();

			this.close();

		}, false);

		// overlay
		const overlay = this.DOM.container.querySelector('.instagram__overlay');
		overlay.addEventListener('click', event =>
		{
			event.preventDefault();
			event.stopPropagation();

			this.close();

		}, false);

		// token
		this.refreshToken();

		// feed
		this.createFeed();

	}

	/*
	** refresh token
	*/

	refreshToken() {

		const xhr = new XMLHttpRequest();

		const url = 'https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=' + this.token;

		xhr.open('GET', url, true);

		xhr.send(null);

	}

	/*
	** create feed
	*/

	createFeed() {

		this.feed = new Instafeed(
		{
			template: this.template,
			accessToken: this.token
		});

		this.feed.run();

	}

	/*
	** open
	*/

	open() {

		// publish
		PubSub.publish('instagram.open');

		// class
		this.DOM.body.classList.add('body--instagram');

	}

	/*
	** close
	*/

	close() {

		// publish
		PubSub.publish('instagram.close');

		// class
		this.DOM.body.classList.remove('body--instagram');

	}

	/*
	** load
	*/

	load() {

		this.init();

	}

	/*
	** unload
	*/

	unload() {

		//

	}

}