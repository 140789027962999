import PubSub from 'pubsub-js';
import Util from '../common/Util.js';
import MySwiper from '../class/MySwiper.js';

export default class Player {

	constructor() {

		this.DOM = {};

		this.DOM.body = document.body;

		this.boundSlideChange = (msg, data) => this.onSlideChange(msg, data);

		this.boundKeyEvent = (event) => this.onKeyEvent(event);

	}

	/*
	** init
	*/

	initClose() {

		this.DOM.close = this.DOM.container.querySelector('.player__close');

		this.DOM.close.addEventListener('click', event =>
		{
			event.preventDefault();
			event.stopPropagation();

			this.close();

		}, false);

	}

	initSwiper() {

		this.swiper = new MySwiper('.player #swiper');

		this.pubSubSwiperSlideChange = PubSub.subscribe('swiper.slideChange', this.boundSlideChange);

		this.swiper.load();

	}

	initThumbnails () {

		this.DOM.thumbnails = document.querySelectorAll('.player__thumbnail');

		const count = this.DOM.thumbnails.length;

		for (let i = 0; i < count; ++i)
		{
			const el = this.DOM.thumbnails[i];
			const index = parseInt(el.getAttribute('data-index'));

			el.style.cursor = 'pointer';

			el.addEventListener('click', event =>
			{
				event.preventDefault();
				event.stopPropagation();

				this.open();

				this.swiper.slideTo(index, 0);

			}, false);
		}

	}

	/*
	** open
	*/

	open() {

		// publish
		PubSub.publish('player.open');

		// key
		document.addEventListener('keydown', this.boundKeyEvent);

		// class
		this.DOM.body.classList.add('body--player-opened');

	}

	/*
	** close
	*/

	close() {

		// publish
		PubSub.publish('player.close');

		// key
		document.removeEventListener('keydown', this.boundKeyEvent);

		// class
		this.DOM.body.classList.remove('body--player-opened');

	}

	/*
	** event
	*/

	onKeyEvent (event) {

		if ( event.keyCode === 27 )
		{
			this.close();
		}

	}

	onSlideChange (msg, data) {

		//

	}

	/*
	** load
	*/

	load() {

		this.DOM.container = document.getElementById('player');

		if (this.DOM.container === null)
		{
			return false;
		}

		// slides
		this.DOM.slides = [...document.querySelectorAll('.swiper-slide')];

		// count
		this.count = this.DOM.slides.length;

		// close
		this.initClose();

		// swiper
		this.initSwiper();

		// thumbnails
		this.initThumbnails();

	}

	/*
	** unload
	*/

	unload() {

		// close
		this.close();

		// pubsub
		if (this.pubSubSwiperSlideChange !== undefined)
		{
			PubSub.unsubscribe(this.pubSubSwiperSlideChange);
			delete this.pubSubSwiperSlideChange;
		}

		// swiper
		if (this.swiper !== undefined)
		{
			this.swiper.unload();
			delete this.swiper;
		}

	}

}