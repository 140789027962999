import MyPage from '../module/MyPage.js';
import Poster from '../module/Poster.js';
import Works from '../module/Works.js';
import WorksFullrow from '../module/WorksFullrow.js';
import Quote from '../module/Quote.js';


export default class Home extends MyPage {

	constructor() {

		super();

		this.name = 'home';

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// poster
		this.poster = new Poster();
		this.poster.load();

		// works
		this.Works = new Works();
		this.Works.load();

		// worksFullrow
		this.worksFullrow = new WorksFullrow();
		this.worksFullrow.load();

		// quote
		this.quote = new Quote();
		this.quote.load();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// poster
		if (this.poster !== undefined)
		{
			this.poster.unload();
			delete this.poster;
		}

		// works
		if (this.Works !== undefined)
		{
			this.Works.unload();
			delete this.Works;
		}

		// worksFullrow
		if (this.worksFullrow !== undefined)
		{
			this.worksFullrow.unload();
			delete this.worksFullrow;
		}

		// quote
		if (this.quote !== undefined)
		{
			this.quote.unload();
			delete this.quote;
		}

	}
}