import Util from '../common/Util.js';
import MyPage from '../module/MyPage.js';
import Poster from '../module/Poster.js';
import Accordion from '../module/Accordion.js';
import Works from '../module/Works.js';
import WorksGrid from '../module/WorksGrid.js';
import AOS from 'aos';

export default class Projects extends MyPage {

	constructor() {

		super();

		this.name = 'projects';

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// aos
		AOS.refreshHard();

		// poster
		this.poster = new Poster();
		this.poster.load();

		// accordion
		this.accordion = new Accordion('.wysiwyg--category');
		this.accordion.load();

		// works
		this.works = new Works();
		this.works.load();

		// worksGrid
		this.worksGrid = new WorksGrid();
		this.worksGrid.load();

		// enter
		const enter = this.DOM.container.querySelector('.page__enter');

		if (enter !== null)
		{
			enter.style.cursor = 'pointer';

			enter.addEventListener('click', event =>
			{
				event.preventDefault();
				event.stopPropagation();

				Util.scrollToElement('div[page-overlay-target]', 1000);

			}, false);
		}

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// poster
		if (this.poster !== undefined)
		{
			this.poster.unload();
			delete this.poster;
		}

		// accordion
		if (this.accordion !== undefined)
		{
			this.accordion.unload();
			delete this.accordion;
		}

		// works
		if (this.works !== undefined)
		{
			this.works.unload();
			delete this.works;
		}

		// worksGrid
		if (this.worksGrid !== undefined)
		{
			this.worksGrid.unload();
			delete this.worksGrid;
		}

	}
}