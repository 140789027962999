import MyPage from '../module/MyPage.js';
import FluidIframes from '../module/FluidIframes.js';
import MyMasonry from '../module/MyMasonry.js';
import Player from '../module/Player.js';
import AOS from 'aos';

export default class Actualities extends MyPage {

	constructor() {

		super();

		this.name = 'actuality';

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// fluidIframes
		this.fluidIframes = new FluidIframes('.videos');
		this.fluidIframes.load();

		// masonry
		this.masonry = new MyMasonry('.images');
		this.masonry.load();

		// player
		this.player = new Player();
		this.player.load();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// fluidIframes
		if (this.fluidIframes !== undefined)
		{
			this.fluidIframes.unload();
			delete this.fluidIframes;
		}

		// masonry
		if (this.masonry !== undefined)
		{
			this.masonry.unload();
			delete this.masonry;
		}

		// player
		if (this.player !== undefined)
		{
			this.player.unload();
			delete this.player;
		}

	}
}