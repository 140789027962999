import Console from '../common/Console.js';
import PageEvent from '../common/PageEvent.js';
import Util from '../common/Util.js';


export default class WorksFullrow extends PageEvent {

	constructor () {

		super();

		this.DOM = {};

		this.count = 0;

		this.rowHeight = 500;

		this.margin = 1;

		this.items = [];

	}

	/*
	** init
	*/

	init() {

		this.DOM.items = this.DOM.container.querySelectorAll('.works__item');

		this.count = this.DOM.items.length;

		for (let i=0; i < this.count; i++)
		{
			const el = this.DOM.items[i];
			const img = el.querySelector('.img');
			const w = parseInt(img.getAttribute('width'));
			const h = parseInt(img.getAttribute('height'));
			const width = (w*this.rowHeight)/h;

			this.items.push(
			{
				'element': el,
				'img': img,
				'width': width,
				'oWidth': w,
				'oHeight': h
			});
		}

	}

	/*
	** grid
	*/

	makeGrid() {

		const containerWidth = this.DOM.container.clientWidth - 2;
		var row = [];
		var rowWidth = 0;
		var marginWidth = 0;

		for (let i=0; i < this.count; i++)
		{
			const item = this.items[i].element;
			const img = this.items[i].img;
			const width = this.items[i].width;

			// item
			item.style.marginRight = this.margin+'px';
			item.style.marginBottom = this.margin+'px';

			// img
			item.style.width = width+'px';
			img.style.width = width+'px';
			img.style.height = this.rowHeight+'px';

			// width
			rowWidth += this.items[i].width;
			marginWidth += this.margin;

			// push
			row.push(this.items[i]);

			if ( (rowWidth+marginWidth) >= (containerWidth+this.margin) )
			{
				this.calculRowHeight(row, rowWidth, containerWidth, this.margin);
				row = [];
				rowWidth = 0;
			}
		}

	}

	calculRowHeight (row, rowWidth, containerWidth, margin) {

		const count = row.length;
		const marginWidth = margin * (count-1);
		const zoneWidth = containerWidth - marginWidth;
		const height = (zoneWidth*this.rowHeight) / rowWidth;

		for (let i=0; i<count; i++)
		{
			const item = row[i].element;
			const img = row[i].img;
			const width = (row[i].width * height)/this.rowHeight;

			item.style.width = width+'px';
			img.style.width = width+'px';
			img.style.height = height+'px';
		}

	}

	/*
	** resize
	*/

	resize (event) {

		if (this.DOM.element === null)
		{
			return false;
		}

		this.makeGrid();

	}

	/*
	** load
	*/

	load() {

		Console.log('WorksFullrow::load');

		// super
		super.load();

		// element
		this.DOM.element = document.querySelector('.works');

		if (this.DOM.element === null)
		{
			return false;
		}

		// margin
		const margin = this.DOM.element.getAttribute('data-fullrow-margin');

		if (margin !== null)
		{
			this.margin = parseInt(margin);
		}

		// rowHeight
		const rowHeight = this.DOM.element.getAttribute('data-fullrow-row-height');

		if (rowHeight !== null)
		{
			this.rowHeight = parseInt(rowHeight);
		}

		// container
		this.DOM.container = this.DOM.element.querySelector('.works__container');

		if (this.DOM.container === null)
		{
			return false;
		}

		// init
		this.init();

		// make grid
		this.makeGrid();

	}

	/*
	** unload
	*/

	unload() {

		Console.log('WorksFullrow::unload');

		// super
		super.unload();

	}

}