import Page from '../common/Page.js';
import Util from '../common/Util.js';


export default class MyPage extends Page {

	constructor() {

		super();

		this.DOM.section = document.querySelector('.section');

		this.name = this.DOM.section.getAttribute('data-controller');

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// overlay
		const overlay = this.DOM.container.querySelector('.page__overlay');

		if (overlay !== null)
		{
			overlay.addEventListener('click', event =>
			{
				event.preventDefault();
				event.stopPropagation();

				Util.scrollToElement('div[page-overlay-target]', 1000);

			}, false);
		}

	}

	/*
	** scrollTo
	*/

	scrollTo (value) {

		Util.setScrollTop(value);

	}

	/*
	** enter
	*/

	enter() {

		// super
		super.enter();

		// class
		setTimeout( () =>
		{
			this.DOM.body.classList.add('body--page-start');

		}, 600);

		this.DOM.body.classList.add('body--page-enter');

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// class
		this.DOM.body.classList.remove('body--page-enter');
		this.DOM.body.classList.remove('body--page-start');

	}

}