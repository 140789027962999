import Spanword from './Spanword.js';

export default class Text
{
	constructor(el) {

		this.DOM = {el: el};

		this.spanword = new Spanword(this.DOM.el);

		this.DOM.wrappers = this.DOM.el.querySelectorAll('.word__wrapper');

	}

	enter() {

		this.DOM.wrappers.forEach( (item, index) =>
		{
			item.style.transitionDelay = index*15 + 'ms';
		});

		this.DOM.el.classList.add('enter');

	}

	leave() {

		this.DOM.el.classList.remove('enter');

	}
}