
export default class Spanword
{
	constructor(el) {

		this.element = el;

		if (this.element === null)
		{
			console.error('el is null');

			return;
		}

		this.process();

	}

	process() {

		this.spanify(this.element);

	}

	spanify (element) {

		const children = Array.from(element.childNodes);

		let elements = [];

		children.forEach(child =>
		{
			if (child.nodeType === Node.TEXT_NODE)
			{
				const words = child.textContent.split(' ');

				let spans = words.map(this.wrappWord);

				elements = elements.concat(
					this.injectElementBetweenItems( spans, document.createTextNode(' ') )
				);
			}
			 else if (child.tagName === 'BR')
			{
				elements.push(child);
			}
			 else
			{
				this.spanify(child);

				elements.push(child);
			}
		});

		element.innerHTML = '';

		elements.forEach(el =>
		{
			element.appendChild(el);
		});

	}

	wrappWord (word) {

		const span1 = document.createElement('span');

		span1.classList.add('word');

		const span2 = document.createElement('span');

		span2.classList.add('word__wrapper');

		span1.appendChild(span2);

		span2.innerHTML = word;

		return span1;

	}

	injectElementBetweenItems (arr, element) {

		return arr.map( (item, index) =>
		{
			if ( index === (arr.length-1) )
			{
				return [item];
			}
			 else
			{
				return [item, element.cloneNode()];
			}

		}).reduce( (acc, pair) =>
		{
			acc = acc.concat(pair);

			return acc;

		}, []);

	}
}
