import Console from '../common/Console.js';


export default class Accordion {

	constructor (selector) {

		this.selector = selector;

		this.DOM = {};

		this.count = 0;

		this.DOM.body = document.body;

		this.timeoutDelay = 800;

	}

	/*
	** init
	*/

	init() {

		// element
		this.DOM.element = document.querySelector(this.selector);

		if (this.DOM.element === null)
		{
			return false;
		}

		this.DOM.items = this.DOM.element.querySelectorAll('div');

		this.count = this.DOM.items.length;

		for (let i=0; i < this.count; i++)
		{
			const el = this.DOM.items[i];

			el.addEventListener('click', event =>
			{
				this.mouseleaveAll();

				el.classList.add('hover');

			}, false);
		}

	}

	mouseleaveAll() {

		for (let i=0; i < this.count; i++)
		{
			const el = this.DOM.items[i];

			el.classList.remove('hover');
		}

	}

	clearTimeout() {

		if (this.timeout !== undefined)
		{
			clearTimeout(this.timeout);
			delete this.timeout;
		}

	}

	/*
	** load
	*/

	load() {

		Console.log('Accordion::load');

		// init
		this.init();

	}

	/*
	** unload
	*/

	unload() {

		Console.log('Accordion:unload');

		// timeout
		this.clearTimeout();

	}

}