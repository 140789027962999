import Masonry from 'masonry-layout';
import PageEvent from '../common/PageEvent.js';
import Util from '../common/Util.js';


export default class MyMasonry extends PageEvent {

	constructor (selector) {

		super();

		this.selector = selector;

	}

	/*
	** init
	*/

	init() {

		// resize
		this.resize();

	}

	/*
	** resize
	*/

	resize (event) {

		if ( !Util.isSmallWidth() )
		{
			if ( this.isSmallwidth || (this.isSmallwidth == undefined) )
			{
				this.createMasonry();

				this.isSmallwidth = false;
			}
		}
		 else
		{
			if ( !this.isSmallwidth || (this.isSmallwidth == undefined) )
			{
				this.destroyMasonry();

				this.isSmallwidth = true;
			}
		}

	}

	/*
	** masonry
	*/

	createMasonry() {

		if (this.msry !== undefined)
		{
			return false;
		}

		const el = document.querySelector(this.selector +'__container');

		if (el === null)
		{
			return false;
		}

		this.msry = new Masonry(el,
		{
			itemSelector       : this.selector +'__item',
			columnWidth        : this.selector +'__sizer',
			gutter             : 0,
			percentPosition    : true,
			transitionDuration : 0,
			horizontalOrder    : false
		});


	}

	destroyMasonry() {

		if (this.msry !== undefined)
		{
			this.msry.destroy();
			delete this.msry;
		}

	}

	/*
	** load
	*/

	load() {

		// super
		super.load();

		// init
		this.init();

	}

	/*
	** unload
	*/

	unload() {

		// destroy
		this.destroyMasonry()

		// super
		super.unload();

	}

}