import PubSub from 'pubsub-js';
import Util from '../common/Util.js';

export default class Reader {

	constructor() {

		this.DOM = {};

		this.DOM.body = document.body;

	}

	/*
	** init
	*/

	init() {

		this.DOM.readers = document.querySelectorAll('.reader');

		this.count = this.DOM.readers.length;

		for (let i = 0; i < this.count; ++i)
		{
			const item = this.DOM.readers[i];
			const id = item.getAttribute('id');
			const text = item.querySelector('.reader__complement');

			if (text !== null)
			{
				const open = item.querySelector('.reader__button--more');
				const close = item.querySelector('.reader__button--less');

				open.addEventListener('click', event =>
				{
					item.classList.add('opened');

					PubSub.publish('reader.change');

				}, false);

				close.addEventListener('click', event =>
				{
					item.classList.remove('opened');

					PubSub.publish('reader.change');

					this.scrollTo(id);

				}, false);
			}
		}

	}

	/*
	** scrollTo
	*/

	scrollTo (id) {

		Util.scrollToElement('#' + id, 1000);

	}

	/*
	** load
	*/

	load() {

		this.init();

	}

	/*
	** unload
	*/

	unload() {

		//

	}

}