import PageEvent from './PageEvent.js';
import Util from './Util.js';


export default class Scroll extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

	}

	/*
	** resize
	*/

	resize (event) {

		this.update();

	}

	/*
	** scroll
	*/

	scroll (event) {

		this.update();

	}

	/*
	** update
	*/

	update() {

		if ( window.innerHeight < Util.getScrollHeight() )
		{
			this.DOM.container.classList.remove('scroll--hidden');
		}
		 else
		{
			this.DOM.container.classList.add('scroll--hidden');
		}

	}

	/*
	** load
	*/

	load() {

		// super
		super.load();

		// container
		this.DOM.container = document.querySelector('.header .scroll');

		// update
		this.update();

	}

	/*
	** load
	*/

	unload() {

		// super
		super.unload();

	}

}
