import Util from '../common/Util.js';
import VideoPlayOnScreen from './VideoPlayOnScreen.js';

export default class Poster {

	constructor() {

		this.DOM = {};

		this.DOM.body = document.body;

	}

	/*
	** load
	*/

	load() {

		this.DOM.container = this.DOM.body.querySelector('.poster');

		if (this.DOM.container === null)
		{
			return false;
		}

		// videoPlayOnScreen
		this.videoPlayOnScreen = new VideoPlayOnScreen();
		this.videoPlayOnScreen.load();

		// class
		this.DOM.body.classList.add('body--poster');

	}

	/*
	** unload
	*/

	unload() {

		// videoPlayOnScreen
		if (this.videoPlayOnScreen !== undefined)
		{
			this.videoPlayOnScreen.unload();
			delete this.videoPlayOnScreen;
		}

		// class
		this.DOM.body.classList.remove('body--poster');

	}

}