import Console from '../common/Console.js';
import PageEvent from '../common/PageEvent.js';
import Util from '../common/Util.js';


export default class WorksGrid extends PageEvent {

	constructor () {

		super();

		this.DOM = {};

		this.count = 0;

		this.items = [];

	}

	/*
	** init
	*/

	init() {

		this.DOM.items = this.DOM.container.querySelectorAll('.works__item');

		this.count = this.DOM.items.length;

		for (let i=0; i < this.count; i++)
		{
			const el = this.DOM.items[i];
			const img = el.querySelector('.img');
			const w = parseInt(img.getAttribute('width'));
			const h = parseInt(img.getAttribute('height'));

			this.items.push(
			{
				'element': el,
				'img': img,
				'oWidth': w,
				'oHeight': h
			});
		}

	}

	/*
	** grid
	*/

	makeGrid() {

		const isMobile = Util.isMobileWidth();

		const wHeight = Math.round( (this.DOM.container.clientWidth * 25) / 100 );

		for (let i=0; i < this.count; i++)
		{
			const item = this.items[i].element;
			const img = this.items[i].img;
			const owidth = this.items[i].oWidth;
			const oheight = this.items[i].oHeight;
			let w = 0;
			let h = 0;

			if ( isMobile )
			{
				w = item.clientWidth;
				h = (w*oheight)/owidth;
			}
			 else
			{
				w = (wHeight*owidth)/oheight;
				h = wHeight;
			}

			// img
			img.style.width  = w + 'px';
			img.style.height = h + 'px';
		}

	}

	/*
	** resize
	*/

	resize (event) {

		if (this.DOM.element === null)
		{
			return false;
		}

		this.makeGrid();

	}

	/*
	** load
	*/

	load() {

		Console.log('WorksGrid::load');

		// super
		super.load();

		// element
		this.DOM.element = document.querySelector('.works');

		if (this.DOM.element === null)
		{
			return false;
		}

		// container
		this.DOM.container = this.DOM.element.querySelector('.works__container');

		if (this.DOM.container === null)
		{
			return false;
		}

		// init
		this.init();

		// make grid
		this.makeGrid();

	}

	/*
	** unload
	*/

	unload() {

		Console.log('WorksGrid::unload');

		// super
		super.unload();

	}

}