import Page from './Page.js';
import Poster from '../module/Poster.js';
import Quote from '../module/Quote.js';

export default class Newsletter extends Page {

	constructor() {

		super();

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// newsletter
		let script1 = document.createElement('script');
		script1.setAttribute('type', 'text/javascript');
		script1.setAttribute('src', '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js');
		this.DOM.container.appendChild(script1);

		let script2 = document.createElement('script');
		script2.setAttribute('type', 'text/javascript');
		script2.innerHTML = "(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);";
		this.DOM.container.appendChild(script2);
	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

	}
}