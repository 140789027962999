import Util from './Util.js';


export default class PageEvent {

	constructor() {

		this.boundEventResize = event => this.resize(event);
		this.boundEventScroll = event => this.scroll(event);

		this.windowWidth = 0;
		this.windowHeight = 0;

	}

	/*
	** resize
	*/

	resize (event) {

		this.windowWidth = Util.windowWidth();
		this.windowHeight = Util.windowHeight();

	}

	/*
	** scroll
	*/

	scroll (event) {

	}

	/*
	** load
	*/

	load() {

		// resize
		window.addEventListener('resize', this.boundEventResize);
		window.addEventListener('orientationchange', this.boundEventResize);

		// scroll
		window.addEventListener('scroll', this.boundEventScroll);

	}

	/*
	** unload
	*/

	unload() {

		// resize
		window.removeEventListener('resize', this.boundEventResize);
		window.removeEventListener('orientationchange', this.boundEventResize);

		// scroll
		window.removeEventListener('scroll', this.boundEventScroll);

	}

}
