import PubSub from 'pubsub-js';
import MyPage from '../module/MyPage.js';
import Poster from '../module/Poster.js';
import Reader from '../module/Reader.js';
import FluidIframes from '../module/FluidIframes.js';
import Quote from '../module/Quote.js';
import MyMasonry from '../module/MyMasonry.js';
import Player from '../module/Player.js';
import WorksGrid from '../module/WorksGrid.js';
import AOS from 'aos';

export default class Project extends MyPage {

	constructor() {

		super();

		this.name = 'project';

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// aos
		AOS.refreshHard();

		// poster
		this.poster = new Poster();
		this.poster.load();

		// reader
		this.reader = new Reader();
		this.reader.load();

		this.pubSubReaderChange = PubSub.subscribe('reader.Change', (msg, data) =>
		{
			AOS.refreshHard();
		});

		// quote
		this.quote = new Quote();
		this.quote.load();

		// fluidIframes
		this.fluidIframes = new FluidIframes('.videos');
		this.fluidIframes.load();

		// masonry
		this.masonry = new MyMasonry('.images');
		this.masonry.load();

		// player
		this.player = new Player();
		this.player.load();

		// worksGrid
		this.worksGrid = new WorksGrid();
		this.worksGrid.load();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// poster
		if (this.poster !== undefined)
		{
			this.poster.unload();
			delete this.poster;
		}

		// pubsub
		if (this.pubSubReaderChange !== undefined)
		{
			PubSub.unsubscribe(this.pubSubReaderChange);
			delete this.pubSubReaderChange;
		}

		// reader
		if (this.reader !== undefined)
		{
			this.reader.unload();
			delete this.reader;
		}

		// fluidIframes
		if (this.fluidIframes !== undefined)
		{
			this.fluidIframes.unload();
			delete this.fluidIframes;
		}

		// quote
		if (this.quote !== undefined)
		{
			this.quote.unload();
			delete this.quote;
		}

		// masonry
		if (this.masonry !== undefined)
		{
			this.masonry.unload();
			delete this.masonry;
		}

		// player
		if (this.player !== undefined)
		{
			this.player.unload();
			delete this.player;
		}

		// worksGrid
		if (this.worksGrid !== undefined)
		{
			this.worksGrid.unload();
			delete this.worksGrid;
		}
	}
}