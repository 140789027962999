import Console from './Console.js';
import Util    from './Util.js';
import Loading from './Loading.js';

export default class Preloading {

	/*
	** show
	*/

	static show() {

		Console.log('Preloading::Show');

		Loading.show();

	}

	/*
	** hide
	*/

	static hide (callback) {

		Console.log('Preloading::hide');

		const preloaderEl = document.querySelector('.preloader');

		setTimeout( () =>
		{
			preloaderEl.classList.add('preloader--show');

			setTimeout( () =>
			{
				Loading.hide();

				// hide
				preloaderEl.classList.add('preloader--hide');

				setTimeout( () =>
				{
					// hidden
					preloaderEl.classList.add('preloader--hidden');

					// callback
					if (callback && typeof(callback) === 'function')
					{
						callback();
					}

				}, 1000);

			}, 2000);

		}, 300);

	}

}