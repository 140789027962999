import PubSub    from 'pubsub-js';
import Console   from './common/Console.js';
import PageEvent from './common/PageEvent.js';
import Util      from './common/Util.js';
import Loading   from './common/Loading.js';
import Instagram   from './module/Instagram.js';

export default class Navigation extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.DOM.body = document.body;

		this.DOM.header = document.querySelector('.header');

		this.lastScroll = 0;

	}

	/*
	** setActive
	*/

	static setActive (controller, id_category) {

		Console.log('Navigation::setActive('+ controller +','+ id_category +')');

		this.selectSection(controller);

		this.selectCategory(id_category);

	}

	static selectSection (controller) {

		// sections
		const sectionsEl = document.querySelectorAll('.sections__anchor');

		for (let i = 0; i < sectionsEl.length; ++i)
		{
			sectionsEl[i].classList.remove('selected');
		}

		// section
		const sectionEl = document.querySelector('.sections__anchor[data-controller="'+ controller +'"]');

		if (sectionEl !== null)
		{
			sectionEl.classList.add('selected');
		}

	}

	static selectCategory (id_category) {

		// categories
		const categoriesEl = document.querySelectorAll('.categories__anchor');

		for (let i = 0; i < categoriesEl.length; ++i)
		{
			categoriesEl[i].classList.remove('selected');
		}

		// category
		const categoryEl = document.querySelector('.categories__anchor[data-id="'+ id_category +'"]');

		if (categoryEl !== null)
		{
			categoryEl.classList.add('selected');
		}

	}

	/*
	** init
	*/

	init() {

		// burger
		this.burger();

		// instagram
		this.instagram = new Instagram();
		this.instagram.load();

	}

	/*
	** burger
	*/

	burger() {

		const burger = this.DOM.header.querySelector('.header__burger');

		burger.addEventListener('click', event =>
		{
			event.preventDefault();
			event.stopPropagation();

			if ( document.body.classList.contains('body--header-opened') )
			{
				this.close();
			}
			 else
			{
				this.open();
			}

		}, false);

	}

	open () {

		this.DOM.body.classList.add('body--header-opened');

	}

	close () {

		this.DOM.body.classList.remove('body--header-opened');

	}

	/*
	** resize
	*/

	resize (event) {

		//

	}

	/*
	** scroll
	*/

	scroll (event) {

		super.scroll(event);

		// scrolled
		this.isScrolled();

		// scrollDown
		this.isScrollDown();

	}

	isScrolled() {

		const scrollTop = Util.getScrollTop();

		if ( scrollTop > 5 )
		{
			this.DOM.body.classList.add('body--scrolled');
		}
		 else
		{
			this.DOM.body.classList.remove('body--scrolled');
		}

		if ( scrollTop > ( this.windowHeight /2 ) )
		{
			this.DOM.body.classList.add('body--xscrolled');
		}
		else
		{
			this.DOM.body.classList.remove('body--xscrolled');
		}

	}

	isScrollDown() {

		const currentScroll = window.pageYOffset;

		if (currentScroll > this.lastScroll)
		{
			if ( !this.DOM.body.classList.contains('scroll-down') )
			{
				this.DOM.body.classList.add('scroll-down');
			}
		}
		 else if (currentScroll < this.lastScroll)
		{
			if ( this.DOM.body.classList.contains('scroll-down') )
			{
				this.DOM.body.classList.remove('scroll-down');
			}
		}

		this.lastScroll = currentScroll;

	}

	/*
	** start
	*/

	start() {

		Console.log('Navigation::start');

		// load
		this.load();

		// init
		this.init();

		// resize
		this.resize();

	}

}